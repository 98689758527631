import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import { SearchVariantFieldsFragment } from '../../../../client/__generated__/graphql-client-types';
import { DEFAULT_VARIANT_GROUP_NUM, SearchVariantContext } from '../../../contexts/search/search-variant.context';
import { TrackedEvent } from '../../../helpers/analytics/event-types';
import { getVariantsToDisplay, getTotalGroups } from '../../../helpers/search-variant/search-variant.helper';
import { TextButton } from '../../buttons';
import { ChevronLeftIcon, ChevronRightIcon } from '../../svg/icons.component';
import { DEFAULT_VARIANT_SIZE, SearchVariant } from '../search-variant/search-variant.component';
import { useTrackEvent } from '../../../hooks/analytics/analytics.hooks';

const SearchVariantRow: FunctionComponent<{ variants: SearchVariantFieldsFragment[]; className?: string; variantSize?: number }> = ({
	variants,
	className = '',
	variantSize = DEFAULT_VARIANT_SIZE
}) => (
	<div className={className} data-testid={`variants-${variantSize}`}>
		{variants.map((variant) => (
			<SearchVariant key={variant.id.toString()} variant={variant} className={`ma0`} variantSize={variantSize} />
		))}
	</div>
);

export type SearchVariantSelectorProps = {
	variants: SearchVariantFieldsFragment[];
	maxNumberToDisplay: number;
	isTileView: boolean;
	allowSingleVariant?: boolean;
};
export const SearchVariantSelector: FunctionComponent<SearchVariantSelectorProps> = ({
	variants,
	maxNumberToDisplay,
	allowSingleVariant,
	isTileView
}) => {
	const { variantGroupDisplayed, setVariantGroupDisplayed } = useContext(SearchVariantContext);
	const [userAlreadyScrolled, setUserAlreadyScrolled] = useState(false);
	const trackEvent = useTrackEvent();

	useEffect(() => {
		setVariantGroupDisplayed(DEFAULT_VARIANT_GROUP_NUM);
	}, [isTileView, setVariantGroupDisplayed]);

	const totalGroups = getTotalGroups(variants.length, maxNumberToDisplay);
	const showLeftArrow = variantGroupDisplayed > 1;
	const showRightArrow = totalGroups !== variantGroupDisplayed;

	if (!allowSingleVariant && variants.length === 1) {
		return null;
	}

	const slicedVariants = getVariantsToDisplay(variants, maxNumberToDisplay, variantGroupDisplayed);
	const arrowSize = `30px`;

	const onVariantScroll = () => {
		if (userAlreadyScrolled) {
			return;
		}
		setUserAlreadyScrolled(true);
		trackEvent({ event: TrackedEvent.PLP_SCROLLED_THROUGH_FINISHES });
	};

	const handleArrowClick = (increment: number) => {
		setVariantGroupDisplayed(variantGroupDisplayed + increment);
		trackEvent({ event: TrackedEvent.PLP_SCROLLED_THROUGH_FINISHES });
	};

	return (
		<div className={`flex`}>
			<div className={`dn ${showLeftArrow ? 'flex-ns' : ''}`} style={{ width: arrowSize, height: arrowSize, marginRight: '.25rem' }}>
				<TextButton
					buttonStyle="PRIMARY"
					testId="left-variant-arrow"
					disabled={variantGroupDisplayed === 0}
					onClick={() => handleArrowClick(-1)}
					className="pa2">
					<ChevronLeftIcon />
				</TextButton>
			</div>
			<div className="overflow-x-auto overflow-x-visible-ns pb2 pb0-l" data-testid="variant-scroll" onScroll={onVariantScroll}>
				<SearchVariantRow variants={variants} className="flex dn-ns" />
				<SearchVariantRow variants={slicedVariants} className="dn flex-ns" variantSize={22} />
			</div>
			<div className={`dn ${showRightArrow ? 'flex-ns' : ''}`} style={{ width: arrowSize, height: arrowSize }}>
				<TextButton testId="right-variant-arrow" buttonStyle="PRIMARY" onClick={() => handleArrowClick(1)} className="pa2">
					<ChevronRightIcon />
				</TextButton>
			</div>
		</div>
	);
};
